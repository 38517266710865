<template>

  <div>
    <v-card>
      <v-card-text>
        <div class="text-center d-inline-block">
          <table class="zui-table mt-2">
            <thead>
              <tr style="font-size:12px;">
                <th rowspan="2">No.</th>
                <th rowspan="2">รายละเอียด</th>
                <th
                  colspan="2"
                  class="text-center"
                >
                  Legal Connect Co., Ltd.</th>
                <th
                  colspan="2"
                  class="text-center"
                >
                  Swift Best Management Co., Ltd.</th>
                <th
                  colspan="2"
                  class="text-center"
                >
                  Strong Solutions Co., Ltd.
                </th>
                <th
                  colspan="2"
                  class="text-center"
                >
                  รวม
                </th>
              </tr>
            </thead>
            <tr>
              <th></th>
              <th></th>
              <th>ทั้งหมด</th>
              <th class="red--text">เกินกำหนด</th>

              <th>ทั้งหมด</th>
              <th class="red--text">เกินกำหนด</th>

              <th>ทั้งหมด</th>
              <th class="red--text">เกินกำหนด</th>

              <th>ทั้งหมด</th>
              <th class="red--text">เกินกำหนด</th>

            </tr>
            <tbody>
              <tr
                v-for="(item, i) in items"
                :key="item.title"
              >
                <td>{{ i+1 }}</td>
                <td class="text-left"><a :href="`#chart${i+1}`">{{ item.title }}</a></td>

                <td>{{ item.models2.find(x=>x.oaId === OA.LC)?.all  | currency(0)}}</td>
                <td>{{ item.models2.find(x=>x.oaId === OA.LC)?.overDue  | currency(0)}}</td>
                <td>{{ item.models2.find(x=>x.oaId === OA.SBM)?.all  | currency(0)}}</td>
                <td>{{ item.models2.find(x=>x.oaId === OA.SBM)?.overDue | currency(0) }}</td>
                <td>{{ item.models2.find(x=>x.oaId === OA.STS)?.all | currency(0) }}</td>
                <td>{{ item.models2.find(x=>x.oaId === OA.STS)?.overDue  | currency(0)}}</td>

                <td>{{ item.models2.find(x=>!x.oaId ).all  | currency(0)}}</td>
                <td>{{ item.models2.find(x=>!x.oaId ).overDue  | currency(0)}}</td>

              </tr>
            </tbody>
          </table>
        </div>
      </v-card-text>
    </v-card>

    <v-row>
      <v-col>
        <ChartStackFollowCase :items="stackItems"></ChartStackFollowCase>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        id="chart1"
      >
        <v-card>
          <v-card-text>
            <v-card-title>1. พิพากษาแล้ว ยังไม่นำคำบังคับ</v-card-title>
            <ChartPieWithZoomDetail
              :isMock="false"
              categoryField="title"
              valueField="value"
              :data="chart1"
            ></ChartPieWithZoomDetail>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-card-title>พิพากษาแล้ว ยังไม่นำคำบังคับ2</v-card-title>
            <PieChart
              :isMock="false"
              categoryField="title"
              valueField="value"
              :data="chart1"
            ></PieChart>
          </v-card-text>
        </v-card>
      </v-col> -->
      <v-col
        cols="12"
        id="chart2"
      >
        <v-card>
          <v-card-text>
            <v-card-title>2. นำคำแล้ว ยังไม่ออกหมาย</v-card-title>

            <ChartPieWithZoomDetail
              :isMock="false"
              categoryField="title"
              valueField="value"
              :data="chart2"
            ></ChartPieWithZoomDetail>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        id="chart3"
      >
        <v-card>
          <v-card-text>
            <v-card-title>3. นำคำแล้ว ยังไม่เบิกหมาย</v-card-title>

            <ChartPieWithZoomDetail
              :isMock="false"
              categoryField="title"
              valueField="value"
              :data="chart3"
            ></ChartPieWithZoomDetail>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        id="chart4"
      >
        <v-card>
          <v-card-text>
            <v-card-title>4. เบิกนำคำแล้ว ยังไม่ส่ง NCB</v-card-title>

            <ChartPieWithZoomDetail
              :isMock="false"
              categoryField="title"
              valueField="value"
              :data="chart4"
            ></ChartPieWithZoomDetail>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        id="chart5"
      >
        <v-card>
          <v-card-text>
            <v-card-title>5. ทำยอมแล้ว ยังไม่ส่ง NCB</v-card-title>

            <ChartPieWithZoomDetail
              :isMock="false"
              categoryField="title"
              valueField="value"
              :data="chart5"
            ></ChartPieWithZoomDetail>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        id="chart6"
      >
        <v-card>
          <v-card-text>
            <v-card-title>6. ผิดนัดทำยอม ยังไม่ออกหมาย</v-card-title>

            <ChartPieWithZoomDetail
              :isMock="false"
              categoryField="title"
              valueField="value"
              :data="chart6"
            ></ChartPieWithZoomDetail>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        id="chart7"
      >
        <v-card>
          <v-card-text>
            <v-card-title>7. ปิดบัญชี (ถอนฟ้อง) ที่ยังไม่เบิก</v-card-title>

            <ChartPieWithZoomDetail
              :isMock="false"
              categoryField="title"
              valueField="value"
              :data="chart7"
            ></ChartPieWithZoomDetail>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        id="chart8"
      >
        <v-card>
          <v-card-text>
            <v-card-title>8. ปิดบัญชี (งดหมาย)</v-card-title>

            <ChartPieWithZoomDetail
              :isMock="false"
              categoryField="title"
              valueField="value"
              :data="chart8"
            ></ChartPieWithZoomDetail>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-fab-transition>
      <v-btn
        color="primary"
        elevation="8"
        dark
        fab
        fixed
        bottom
        right
        @click="$vuetify.goTo('#app', {duration: 500, offset: 0})"
      >
        <v-icon>mdi-chevron-up</v-icon>
      </v-btn>
    </v-fab-transition>

  </div>
</template>

<script>
import PieChart from "./../components/amcharts/pie-chart-with-legend.vue";
import ChartPieWithZoomDetail from "../components/amcharts/chart-pie-with-zoom-detail.vue";
import ChartStackFollowCase from "./chart-stack-followcase.vue";
import { OA } from "./../js/constants";
export default {
  components: {
    PieChart,
    ChartPieWithZoomDetail,
    ChartStackFollowCase,
  },
  data() {
    return {
      OA,
      openCasese: [],
      chart1: [],
      chart2: [],
      chart3: [],
      chart4: [],
      chart5: [],
      chart6: [],
      chart7: [],
      chart8: [],
      items: [],
      stackItems: [],
    };
  },
  created() {
    let promises = [];

    for (let index = 1; index <= 8; index++) {
      this.items.push({
        models2: [
          {
            oaId: null,
          },
          {
            oaId: null,
          },
          {
            oaId: null,
          },
        ],
      });

      promises.push(
        this.$http
          .get(`/report/ChartFollowCase?chartType=${index}`)
          .then((res) => {
            this["chart" + index].push({
              title: "เกินกำหนด",
              value: res.overDue,
              subData: res.overDueData.subData,
              color: "#cc3300",
            });

            this["chart" + index].push({
              title: "ยังไม่เกินกำหนด",
              value: res.inProcess,
              subData: res.inProcessData.subData,
              color: "#99cc33",
            });

            this.items.splice(res.order - 1, 1, res);

            return res;
          })
      );
    }

    Promise.all(promises).then((reses) => {
      reses = _.orderBy(reses, ["order"], ["asc"]);
      reses.forEach((res) => {
        this.stackItems.push({
          title: res.title,
          overDue: res.overDue,
          inProcess: res.inProcess,
        });
      });
    });
  },
  methods: {},
};
</script>

<style scoped>
.zui-table {
  border: solid 1px #ddeeee;
  border-collapse: collapse;
  border-spacing: 0;
  width: 1080px;
  /* width: 100%; */
  /* font: normal 13px Arial, sans-serif; */
}
.zui-table thead th {
  background-color: #ddefef;
  border: solid 1px #ddeeee;
  color: #336b6b;
  padding: 5px;
  text-align: left;
  /* text-shadow: 1px 1px 1px #fff; */
}
.zui-table tbody td {
  border: solid 1px #ddeeee;
  color: #333;
  padding: 5px;
  /* text-shadow: 1px 1px 1px #fff; */
}
</style>
