
<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);
import BaseChart from "./base-chart";

import amchartMixins from "../../_mixins/amchart-mixins";

export default {
  name: "pie-chart-with-legend",
  mixins: [amchartMixins],
  extends: BaseChart,
  props: {
    categoryField: {
      type: String,
      default: "country",
    },
    valueField: {
      type: String,
      default: "litres",
    },
  },
  data() {
    return {
      url: "https://www.amcharts.com/demos-v4/pie-of-a-pie-v4/",
      selectedSlice: null,
      pieSeries: null,
      pieSeries2: null,
      chart2: null,
      line1: null,
      line2: null,
    };
  },
  methods: {
    mockup() {
      let data = [
        {
          country: "Lithuania",
          litres: 501.9,
        },
        {
          country: "Germany",
          litres: 165.8,
        },
        {
          country: "Australia",
          litres: 139.9,
        },
        {
          country: "Austria",
          litres: 128.3,
        },
        {
          country: "UK",
          litres: 99,
        },
        {
          country: "Belgium",
          litres: 60,
        },
      ];
      return data;
    },
    createChart() {
      var container = am4core.create(this.$refs.chartdiv, am4core.Container);
      container.width = am4core.percent(85);
      container.height = am4core.percent(100);
      container.layout = "horizontal";

      let chart = container.createChild(am4charts.PieChart);
      this.chart = chart;

      // Add data
      chart.data = this.getData();

      // Add and configure Series
      // var pieSeries = chart.series.push(new am4charts.PieSeries());
      // pieSeries.dataFields.value = this.valueField;
      // pieSeries.dataFields.category = this.categoryField;
      this.pieSeries = chart.series.push(new am4charts.PieSeries());
      this.pieSeries.dataFields.value = "value";
      this.pieSeries.dataFields.category = "title";
      this.pieSeries.slices.template.propertyFields.fill = "color";

      this.pieSeries.slices.template.states.getKey(
        "active"
      ).properties.shiftRadius = 0;

      this.pieSeries.slices.template.events.on("hit", (event) => {
        this.selectSlice(event.target.dataItem);
      });

      this.chart2 = container.createChild(am4charts.PieChart);
      this.chart2.width = am4core.percent(30);
      this.chart2.radius = am4core.percent(80);

      this.pieSeries2 = this.chart2.series.push(new am4charts.PieSeries());
      this.pieSeries2.dataFields.value = "value";
      this.pieSeries2.dataFields.category = "name";
      this.pieSeries2.slices.template.states.getKey(
        "active"
      ).properties.shiftRadius = 0;

      this.pieSeries2.labels.template.disabled = false;
      this.pieSeries2.ticks.template.disabled = false;
      this.pieSeries2.alignLabels = false;
      this.pieSeries2.events.on("positionchanged", this.updateLines);

      var interfaceColors = new am4core.InterfaceColorSet();

      this.line1 = container.createChild(am4core.Line);
      this.line1.strokeDasharray = "2,2";
      this.line1.strokeOpacity = 0.5;
      this.line1.stroke = interfaceColors.getFor("alternativeBackground");
      this.line1.isMeasured = false;

      this.line2 = container.createChild(am4core.Line);
      this.line2.strokeDasharray = "2,2";
      this.line2.strokeOpacity = 0.5;
      this.line2.stroke = interfaceColors.getFor("alternativeBackground");
      this.line2.isMeasured = false;

      // Add a legend
      chart.legend = new am4charts.Legend();
    },
    selectSlice(dataItem) {
      this.selectedSlice = dataItem.slice;

      var fill = this.selectedSlice.fill;

      var count = dataItem.dataContext.subData.length;
      this.pieSeries2.colors.list = [];
      for (var i = 0; i < count; i++) {
        this.pieSeries2.colors.list.push(fill.brighten((i * 2) / count));
      }

      this.chart2.data = dataItem.dataContext.subData;
      this.pieSeries2.appear();

      var middleAngle = this.selectedSlice.middleAngle;
      var firstAngle = this.pieSeries.slices.getIndex(0).startAngle;
      var animation = this.pieSeries.animate(
        [
          { property: "startAngle", to: firstAngle - middleAngle },
          { property: "endAngle", to: firstAngle - middleAngle + 360 },
        ],
        600,
        am4core.ease.sinOut
      );
      animation.events.on("animationprogress", this.updateLines);

      this.selectedSlice.events.on("transformed", this.updateLines);

      //  var animation = this.chart2.animate({property:"dx", from:-container.pixelWidth / 2, to:0}, 2000, am4core.ease.elasticOut)
      //  animation.events.on("animationprogress", updateLines)
    },
    updateLines() {
      if (this.selectedSlice) {
        var p11 = {
          x:
            this.selectedSlice.radius *
            am4core.math.cos(this.selectedSlice.startAngle),
          y:
            this.selectedSlice.radius *
            am4core.math.sin(this.selectedSlice.startAngle),
        };
        var p12 = {
          x:
            this.selectedSlice.radius *
            am4core.math.cos(
              this.selectedSlice.startAngle + this.selectedSlice.arc
            ),
          y:
            this.selectedSlice.radius *
            am4core.math.sin(
              this.selectedSlice.startAngle + this.selectedSlice.arc
            ),
        };

        p11 = am4core.utils.spritePointToSvg(p11, this.selectedSlice);
        p12 = am4core.utils.spritePointToSvg(p12, this.selectedSlice);

        var p21 = { x: 0, y: -this.pieSeries2.pixelRadius };
        var p22 = { x: 0, y: this.pieSeries2.pixelRadius };

        p21 = am4core.utils.spritePointToSvg(p21, this.pieSeries2);
        p22 = am4core.utils.spritePointToSvg(p22, this.pieSeries2);

        this.line1.x1 = p11.x;
        this.line1.x2 = p21.x;
        this.line1.y1 = p11.y;
        this.line1.y2 = p21.y;

        this.line2.x1 = p12.x;
        this.line2.x2 = p22.x;
        this.line2.y1 = p12.y;
        this.line2.y2 = p22.y;
      }
    },
  },
};
</script>

