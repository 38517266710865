<template>
  <div>
    <!-- <v-card-title>1 Bar chart and 8 Pie charts</v-card-title> -->
    <StackedColumnChart
      class="mt-10"
      :key="count"
      :isMock="false"
      :data="items"
      categoryField="title"
      valueText="จำนวนเคส"
      :valueFields="[
      { key: 'overDue', name: 'เกินกำหนด' , color: '#cc3300'},
      { key: 'inProcess', name: 'ยังไม่เกินกำหนด', color: '#99cc33' }]"
    ></StackedColumnChart>
  </div>
</template>


<script>
// import LayeredColumnChart from "./../../components/amcharts/layered-column-chart";
// import StackedColumnChart from "./../components/stacked-column-chart";
import StackedColumnChart from "../components/amcharts/stacked-column-chart.vue";
//import { getFakeUrl, fakeAbbr } from "./../../_helpers/fake-url";

export default {
  components: {
    StackedColumnChart,
  },
  props: {
    items: Array,
  },
  data() {
    return {
      // items: [],
      count: 0,
    };
  },
  watch: {
    items: {
      handler(newVal) {
        this.count++;
      },
      immediate: true,
    },
  },
  created() {
    // this.getData();
  },
  methods: {
    // getData() {
    //   let url = "";
    //   if (this.$route.name === "dashboard-management") {
    //     url = "/dashboard/management/DistinctCaseStatusBy12Months";
    //   } else if (this.$route.name === "dashboard-lawyer") {
    //     url = "/dashboard/lawyer/DistinctCaseStatusBy12Months";
    //     let lawyerId = this.$route.params.lawyerId;
    //     if (lawyerId) {
    //       url += `?lawyerId=${lawyerId}`;
    //     }
    //   }
    //   this.$http.get(url).then((res) => {
    //     this.items = [];
    //     this.items = res.items;
    //   });
    // },
  },
};
</script>